import { Flex } from '@stars-ecom/shared-atoms-ui'
import { Help } from '@stars-ecom/shared-organisms-ui'
import React from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const HelpPage = (props) => {
  const { pageContext } = props

  const { helpPage } = pageContext

  return (
    <Flex
      className="help-container"
      width="100%"
      backgroundColor="#f6f3f1"
      position={{ base: 'relative', md: 'initial' }}
      mt={{ base: '12px', md: '0' }}
      top="-50px"
      zIndex="-1">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Aide - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta
          name="description"
          content={`Besoin d'aide? ${siteFormatter(
            process.env.GATSBY_API_WEBSITE
          )} est là pour répondre à vos attentes et met tout en oeuvre pour vous satisfaire`}
        />
      </Helmet>
      <Help help={helpPage} />
    </Flex>
  )
}

HelpPage.pageType = 'help'
export default HelpPage
